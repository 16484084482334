import * as React from "react";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Content from "../../components/layout/Content/Content";
import Layout from "../../components/layout/Layout";
import Banner from "../../components/pages/no-tutorial-needed/Banner";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import Shoutout from "../../components/pages/signup/Shoutout";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const NoTutorialNeeded = ({ data }) => (
  <Layout>
    <Meta
      title="Try Uizard"
      description="Design apps and websites with Uizard in minutes! No prior design or development experience required!"
      url="/no-tutorial-needed/"
    />

    <Container>
      <Banner imageA={data.bannerImageA.childImageSharp} />

      <HomeFeatured />

      <ContentExtended>
        <Shoutout
          headline="Design made easy"
          description="Create apps and websites in minutes"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Container>
  </Layout>
);

export default NoTutorialNeeded;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(
      relativePath: { eq: "no-tutorial-needed/no-tutorial-needed-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
