import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Description from "../../../texts/Description";
import Content from "../../../layout/Content/Content";
import HomeBannerIllustration from "../../home/HomeBannerIlustration";
import Button from "../../../buttons/Button";
// @ts-ignore
import Headline from "../../../texts/Headline";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  margin: 15rem 0 3rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    margin: 15rem 0 8rem 0;
  `}
`;

const Columns = Styled.div`
  flex-direction: column;
`;

const Column = Styled.div`
  text-align: center;
`;

const ColumnText = Styled(Column)`
  margin-bottom: 4rem;
  padding-right: 0;

  ${Media.greaterThan("large")`
    margin-bottom: 0;
  `}
`;

const ColumnIllustration = Styled(Column)`
  margin-top: 6rem;

  ${Media.greaterThan("medium")`
    margin-top: 15rem;
  `}
`;

const Header = Styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

const HeadlineWrapper = Styled.div`
  max-width: 100%;
  margin: 0;
  
  svg {
    max-width: 100%;
  }

  ${Media.greaterThan("medium")`
    margin: 0
  `}
`;

const DescriptionExtended = Styled(Description)`
  width: 28.5rem;

  ${Media.greaterThan("700px")`
    width: auto;
    margin-bottom: 3rem;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  font-size: 3.6rem;
  line-height: 3.8rem;
  max-width: 100%;
  margin: 0 0 2rem 0;
  
  ${Media.greaterThan("medium")`
    width: auto;
    margin: 0 0 3rem 0;
  `}

  ${Media.greaterThan("large")`
    font-size: 5.6rem;
    line-height: 6.5rem;
  `}
`;

const NavButtonLink = Styled.a`
  
`;

const w = 1920;
const h = 1020;

const VideoContainer = Styled.div`
  position: relative;
  height: 0;
  width: ${w}px;
  max-width: 100%;
  padding-bottom: ${Math.round((h / w) * 100)}%;
  margin: 0 auto;
  box-shadow: 0px 2px 20px rgba(3,3,3,0.1);
  
  ${Media.greaterThan("medium")`
    margin: 0;
  `}
`;

const VideoContent = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Video = Styled.video`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
`;

const ImageA = Styled(Image).attrs({
  style: {
    position: "absolute",
  },
})`
  position: absolute;
  width: 100%;
  z-index: 0;
`;

type BannerProps = {
  imageA: GatsbyImageProps;
};

const Banner = ({ imageA }: BannerProps) => (
  <Container>
    <Content>
      <Columns>
        <ColumnText>
          <Header>
            <HeadlineWrapper>
              <ExtendedHeadline as="h1">
                No tutorials required.
              </ExtendedHeadline>
            </HeadlineWrapper>

            <DescriptionExtended>
              If you can use Keynote or Powerpoint, you can use Uizard!
            </DescriptionExtended>

            <NavButtonLink
              href={`https://${constants.APP_DOMAIN}/auth/sign-up`}
            >
              <Button solid>Try it for free</Button>
            </NavButtonLink>
          </Header>
        </ColumnText>

        <ColumnIllustration>
          <VideoContainer>
            <VideoContent>
              <Video autoPlay muted loop playsInline>
                <source
                  src="/videos/uizard-drag-and-drop.mp4"
                  type="video/mp4"
                />
                <source
                  src="/videos/uizard-drag-and-drop.ogv"
                  type="video/ogv"
                />
                <source
                  src="/videos/uizard-drag-and-drop.webm"
                  type="video/webm"
                />
                <img
                  src="/videos/uizard-drag-and-drop.png"
                  alt="Hero video showing that Uizard is easy to use"
                />
              </Video>
              <ImageA
                fluid={"fluid" in imageA ? imageA.fluid : null}
                alt="Hero image showing that Uizard is easy to use"
              />
            </VideoContent>
          </VideoContainer>
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);

export default Banner;
